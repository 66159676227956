import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';
import { exhaustMap, filter } from 'rxjs/operators';

import { AuthService } from 'projects/shared/src/lib/auth/auth/data-access/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private auth = inject(AuthService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('/assets')) {
      return next.handle(request);
    } else {
      return this.auth.isUserSet$.asObservable().pipe(
        filter((isUserSet) => isUserSet),
        exhaustMap((user) => {
          return user && this.auth.user()
            ? this.handleToken(request, next, this.auth.user())
            : next.handle(request);
        }),
      );
    }
  }

  private handleToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    fbUser: any,
  ): Observable<HttpEvent<any>> {
    const authHeader: string = 'authorization';
    const forceTokenRefresh = false;
    return from(fbUser.getIdToken(forceTokenRefresh)).pipe(
      exhaustMap((idToken) => {
        const authReq = request.clone({
          headers: request.headers.set(authHeader, `Bearer ${idToken}`),
        });
        return next.handle(authReq);
      }),
    );
  }
}
