import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

const BACKENDURL = environment.backendBaseURL;
const SUBSCRIPTIONURL = environment.subscriptionServiceBaseURL;
const SUBSCRIPTIONENDPOINT = environment.subscriptionEndpoint;

@Injectable({
  providedIn: 'root',
})
export class BaseUrlInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.includes(SUBSCRIPTIONENDPOINT)) {
      return next.handle(
        req.clone({
          url: `${SUBSCRIPTIONURL}${req.url}`,
        }),
      );
    } else if (this.isNonBaseURLRequest(req)) {
      return next.handle(
        req.clone({
          url: `${req.url}`,
        }),
      );
    } else {
      return next.handle(
        req.clone({
          url: `${BACKENDURL}/${req.url}`,
        }),
      );
    }
  }

  private isNonBaseURLRequest(req: HttpRequest<any>): boolean {
    return (
      environment.local ||
      req.url.includes('/assets') ||
      req.url.includes('storage.googleapis')
    );
  }
}
