<div class="footer-container">
  <mat-toolbar class="footer">
    <ng-container
      *ngIf="
        footerMenuElements
          ? footerMenuElements
          : footerMenuElements$() as footerMenu
      "
    >
      @for (menuItem of footerMenu.nameUrls | keyvalue; track menuItem) {
        <div class="link-box">
          @if (footerMenu.matIcons[menuItem.key]) {
            <mat-icon>{{ footerMenu.matIcons[menuItem.key] }}</mat-icon>
          }
        </div>
        <div class="link-box">
          <a (click)="navigateTo(menuItem.value)">
            {{ menuItem.key }}
          </a>
        </div>
      }
    </ng-container>
  </mat-toolbar>
</div>
