<div class="home-parent-container" @fadeSlideInOut>
  <div class="header-container">
    <img class="image" src="/assets/img/hands.png" />
    <div class="text-block">
      <h3>{{ entitlementsConfig()?.entitlementsTitle }}</h3>
      {{ entitlementsConfig()?.entitlementsDescription }}
    </div>
  </div>
  <div class="responsive-grid" [@races]="subscriptions.length">
    @for (subscription of subscriptions; track subscription) {
      <mat-card
        class="card"
        (click)="handleSubId(subscription.subscription_id)"
      >
        <mat-card-header>
          <mat-card-title>{{ subscription.service_nickname }}</mat-card-title>
        </mat-card-header>
        <img
          class="card-image"
          mat-card-image
          src="assets/img/image-preview.png"
        />
        <mat-card-content>
          <div class="info-grid">
            <mat-icon>account_circle</mat-icon>
            <div class="">{{ subscription.email }}</div>
            <mat-icon>cloud_circle</mat-icon>
            <div class="">{{ subscription.subscription_id }}</div>
          </div>
        </mat-card-content>
      </mat-card>
    }
  </div>
</div>
