import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import LoginComponent from '@shared-features/login/login.component';
import { SubIdSelectorComponent } from '@shared-features/sub-id-selector/sub-id-selector.component';
import { isAuthenticatedGuard } from 'projects/shared/src/lib/auth/guards/auth.guard';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'home',
    loadComponent: () =>
      import('./features/home/home.component').then((mod) => mod.HomeComponent),
    canActivate: [isAuthenticatedGuard],
  },
  {
    path: 'selector',
    component: SubIdSelectorComponent,
    canActivate: [isAuthenticatedGuard],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
