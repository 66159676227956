import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { Subscription } from '@shared-interfaces/shared-subscription';

const SUBSCRIPTIONENDPOINT = environment.subscriptionEndpoint;

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private httpClient = inject(HttpClient);

  getSubscriptionIds(): Observable<Subscription[]> {
    return this.httpClient.get<Subscription[]>(
      SUBSCRIPTIONENDPOINT,
      this.getHttpOptions(),
    );
  }

  private getHttpOptions(): object {
    const params = new HttpParams().set('product_id', environment.product_id);
    return {
      params,
    };
  }
}
