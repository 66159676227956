<div class="home-parent-container" @fadeSlideInOut>
  <img class="image" src="/assets/img/hands.png" />
  <div class="text-block">
    <h3>{{ loginConfig?.loginTitle }}</h3>
    {{ loginConfig?.loginDescription }}
  </div>
  <form [formGroup]="loginForm" (ngSubmit)="login.emit()">
    <button
      mat-raised-button
      color="accent"
      type="submit"
      [disabled]="loginStatus === 'authenticating'"
    >
      Login
    </button>
  </form>
</div>
