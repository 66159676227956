import { Injectable, inject, signal } from '@angular/core';
import { HeaderFooterElements } from '@shared-interfaces/shared-header-footer';
import { ConfigurationService } from '@shared-utils/configuration/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  private configService = inject(ConfigurationService);
  footerConfig = signal<HeaderFooterElements | undefined>(undefined);

  getMenuItems(): void {
    this.configService.getConfigItem('/assets/footer.json').subscribe({
      next: (footerElements: HeaderFooterElements) => {
        this.footerConfig.set(footerElements);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}
