import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { HeaderFooterElements } from '@shared-interfaces/shared-header-footer';
import { FooterService } from './data-access/footer.service';

@Component({
  selector: 'shared-footer',
  standalone: true,
  imports: [MatToolbarModule, MatButtonModule, CommonModule, MatIconModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  private router = inject(Router);
  private footerService = inject(FooterService);
  footerMenuElements$ = this.footerService.footerConfig;

  @Input() footerMenuElements: HeaderFooterElements | undefined = undefined;

  ngOnInit(): void {
    this.footerService.getMenuItems();
  }

  navigateTo(path: string | { [key: string]: string }): void {
    if (typeof path === 'string') {
      // this.router.navigate([path], {
      //   queryParamsHandling: 'merge',
      // });
    }
  }
}
