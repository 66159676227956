import { Injectable, inject, signal } from '@angular/core';
import { EntitlementsConfig } from '@shared-interfaces/shared-entitlements';
import { ConfigurationService } from '@shared-utils/configuration/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class SubIdSelectorService {
  private configService = inject(ConfigurationService);
  entitlementsConfig = signal<EntitlementsConfig | undefined>(undefined);

  getEntitlementsConfig(): void {
    this.configService.getConfigItem('/assets/entitlements.json').subscribe({
      next: (entitlementsConfig: EntitlementsConfig) => {
        this.entitlementsConfig.set(entitlementsConfig);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}
