import { Component, OnInit, effect, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LoginFormComponent } from './ui/login-form.component';
import { LoginService } from './data-access/login.service';
import { AuthService } from 'projects/shared/src/lib/auth/auth/data-access/auth.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { environment } from '@environments/environment';

@Component({
  standalone: true,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  imports: [
    RouterModule,
    LoginFormComponent,
    MatProgressSpinnerModule,
    CommonModule,
  ],
})
export default class LoginComponent implements OnInit {
  public loginService = inject(LoginService);
  public authService = inject(AuthService);
  private router = inject(Router);
  protected showLoginForm: boolean = false;

  constructor() {
    effect(() => {
      if (this.authService.user()) {
        this.router.navigate([environment.homeUrl], {
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  ngOnInit(): void {
    this.loginService.getLoginConfig();
    setTimeout(() => {
      this.showLoginForm = true;
    }, 400);
  }
}
