import { Component, Signal, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from '@shared-features/header/header.component';
import { FooterComponent } from '@shared-features/footer/footer.component';
import { BreakpointObserver, LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {
  AuthService,
  AuthUser,
} from 'projects/shared/src/lib/auth/auth/data-access/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    LayoutModule,
    CommonModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'app';
  authService = inject(AuthService);
  breakpointObserver = inject(BreakpointObserver);
  layoutChanges$ = this.breakpointObserver.observe([
    '(orientation: portrait)',
    '(orientation: landscape)',
    '(max-width: 480px)',
  ]);
  user$: Signal<AuthUser> = this.authService.user;
}
