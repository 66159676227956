<div class="container gradient-bg">
  @if (!authService.user()) {
    @if (showLoginForm) {
      <app-login-form
        [loginStatus]="loginService.status()"
        [loginConfig]="loginService.loginConfig()"
        (login)="loginService.login$.next($event)"
      ></app-login-form>
    }
  } @else if (authService.user()) {
    <!-- <mat-spinner class="spinner" color="primary"></mat-spinner> -->
    <img class="robot" src="/assets/img/robot.svg" />
  }
</div>
