
<div class="header-wrapper">
  <shared-header></shared-header>
</div>

<div class="content-wrapper">
  <router-outlet></router-outlet>
</div>
<!-- Only display sticky footer when not mobile -->
@if (!(layoutChanges$ | async)?.breakpoints?.["(max-width: 480px)"]) {
  <div class="footer-wrapper">
    <shared-footer></shared-footer>
  </div>
}
