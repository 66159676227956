import { inject } from '@angular/core';
import { CanActivateFn, NavigationExtras, Router } from '@angular/router';
import { AuthService } from 'projects/shared/src/lib/auth/auth/data-access/auth.service';

export const isAuthenticatedGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  var subId = route.queryParams?.['subscription_id'] ?? '';
  const regex =
    /^sub_[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  if (!regex.test(subId)) {
    subId = '';
  }
  const queryParams: NavigationExtras | undefined = subId
    ? {
        queryParams: { subscription_id: subId },
        queryParamsHandling: 'merge',
      }
    : {};

  
  if (route.url[0].path.includes('selector')) {
    return true;
  }
  if (!subId) {
    router.navigate(['selector'], queryParams);
  }
  return true;
};
