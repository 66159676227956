import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';

@Injectable()
export class SubIdInterceptor implements HttpInterceptor {
  private route = inject(ActivatedRoute);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.route.queryParams.pipe(
      map((params) => params['subscription_id']),
      exhaustMap((subscriptionId) => {
        const modifiedReq = req.clone({
          headers: req.headers.set('subscription-id', subscriptionId ?? ''),
        });
        return next.handle(modifiedReq);
      }),
    );
  }
}
