import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  effect,
  inject,
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subscription } from '@shared-interfaces/shared-subscription';
import { environment } from '@environments/environment';
import { CommonModule } from '@angular/common';
import { CdkMenuModule } from '@angular/cdk/menu';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AuthService,
  AuthUser,
} from '../../auth/auth/data-access/auth.service';
import { SubIdSelectorService } from './data-access/sub-id-selector.service';
import { SubscriptionService } from '@shared-utils/subscription/subscription.service';

@Component({
  selector: 'shared-sub-id-selector',
  standalone: true,
  imports: [CommonModule, CdkMenuModule, MatCardModule, MatIconModule],
  templateUrl: './sub-id-selector.component.html',
  styleUrl: './sub-id-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('races', [
      transition('* => *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateY(10px)' }),
            stagger(100, [
              animate(
                '400ms ease-in',
                style({
                  opacity: 1,
                  transform: 'translateY(0%)',
                }),
              ),
            ]),
          ],
          { optional: true },
        ),
      ]),
    ]),
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('500ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class SubIdSelectorComponent {
  subscriptions: Subscription[] = [];
  subId: string = '';
  assetTitle = environment.assetTitle;
  states: boolean[] = [];
  index = -1;

  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private authService = inject(AuthService);
  private subscriptionService = inject(SubscriptionService);
  private subIdSelectorService = inject(SubIdSelectorService);
  user$: Signal<AuthUser> = this.authService.user;
  entitlementsConfig = this.subIdSelectorService.entitlementsConfig;

  ngOnInit(): void {
    this.subIdSelectorService.getEntitlementsConfig();
  }

  constructor() {
    effect(() => {
      if (this.entitlementsConfig()) this.initializeSubIdFromQueryParams();
    });
  }

  protected handleSubId(subId: string): void {
    const queryParams: NavigationExtras | undefined = subId
      ? {
        queryParams: { subscription_id: subId },
      }
      : {};
    this.router.navigate(['home'], queryParams);
    this.subId = subId;
  }

  private initializeSubIdFromQueryParams(): void {
    this.route.queryParams.subscribe((params) => {
      this.subId = params['subscription_id'] || '';
      if (!this.subId) {
        this.loadAndHandleSubscriptions();
      }
    });
  }

  private loadAndHandleSubscriptions(): void {
    const config = this.entitlementsConfig();
    if (config && config.useDefaultSubId && config.defaultSubscriptionData) {
      this.subscriptions = [
        {
          ...config.defaultSubscriptionData,
          email: this.user$()?.email
            ? this.user$()!.email!
            : config.defaultSubscriptionData.email,
        },
      ];
    } else {
      this.subscriptionService
        .getSubscriptionIds()
        .subscribe((subscriptions: Subscription[]) => {
          this.subscriptions = subscriptions.sort((a, b) =>
            a.service_nickname > b.service_nickname ? 1 : -1,
          );
          this.redirectIfSingleSubscription();
        });
    }
  }

  private redirectIfSingleSubscription(): void {
    if (
      this.subscriptions.length === 1 &&
      this.entitlementsConfig()?.redirectIfSingleSubscription
    ) {
      this.handleSubId(this.subscriptions[0].subscription_id);
    }
    this.animationStart();
  }

  animationStart() {
    for (let sub of this.subscriptions) {
      this.states.push(false);
    }
    setTimeout(() => {
      this.index = 0;
      this.states[0] = true;
    });
  }
  animationDone() {
    this.index++;
    if (this.index < this.states.length) this.states[this.index] = true;
    else this.index = -1;
  }
}
