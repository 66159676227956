import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoaderService } from '@shared-utils/loader/loader.service';
import { Observable } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoaderInterceptor implements HttpInterceptor {
  public static HIDE_LOADING_BAR = `DeloitteAiHideLoadingBar`;

  constructor(private loaderService: LoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.loaderService.show();

    return next.handle(req).pipe(
      filter((event) => event instanceof HttpResponse),
      finalize(() => {
        this.loaderService.hide();
      }),
      take(1),
    );
  }
}
